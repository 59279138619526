.dialog-title {
    color: #ff8e33;
}
.dialog-box{
    color: #090a0c !important;
    
    .container{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 500px;
        p{
            overflow-wrap: anywhere;
            text-align: center;
        }
    }
}