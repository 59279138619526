.product-number-list-page {
    .btn-wrapper {
        width: 150px;
        margin: 20px 0;
    }
    .table-wrapper {
        margin: 30px;
    }
    .product-number-page {
        margin: 30px;
        .inventory-lists {
            grid-template-columns: 20% 20% 20% 20%;
            display: grid;
            column-gap: 10px;
            p {
                padding: 15px;
                background: #f2f2f2;
                width: 100%;
                font-weight: 600;
                color: #3f4042;
                border-radius: 15px;
                cursor: pointer;
            }
        }
    }
    .product-number-list-table {
        padding: 25px;
        width: 100%;
        border: 1px solid #f5f5f5;
        background-color: #fff;
        box-shadow: 0 1px 5px rgb(0 0 0 / 16%);
        .action-btn-wrap {
            .btn-color-wrap {
                width: 100px;
                margin: 0 10px;
            }
            .btn-outline-wrap {
                width: 100px;
                margin: 0 10px;
            }
        }
        th,
        td {
            border-right: none;
            border-left: none;
        }
        td {
            padding: 20px;
            color: #636363;
            font-size: 13px;
            font-weight: 400;
            z-index: 1;
            text-align: center;
        }
        th {
            padding: 20px 10px;
            color: #090a0c;
            font-size: 15px;
            font-weight: 600;

            top: 0;
            z-index: 1;
            text-align: center;
            font-family: "Roboto";
            border: 0;
            background-color: transparent;
        }
        th:first-child,
        th:nth-child(2) {
            width: auto !important;
        }
        td {
            .btn-action {
                margin: 0 !important;
                height: 30px;
                text-transform: uppercase;
            }
        }
    }
    .no-products {
        text-align: center;
        display: flex;
        justify-content: center;
        height: 20%;
        align-items: center;
        .no-products-card-wrapper {
            align-items: center;
            padding: 50px;
            background: #f2f2f2;
            width: 50%;
            border-radius: 15px;
        }
    }
    .card-container {
        margin: 30px;
        display: grid;
        grid-template-columns: 15% 15% 15% 15% 15% 15%;
        column-gap: 10px;
        row-gap: 20px;
    }
}
.delete-Popup {
    .MuiDialogTitle-root {
        display: flex;
        justify-content: center;
        color: #ff0000;
        .MuiSvgIcon-root {
            font-size: 40px;
        }
    }
}
.product_Card{
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
    margin: 20px;
}