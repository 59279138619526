.history-page {
    padding: 20px;

    .header-component {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;
    }

    .history-table {
        width: 100%;
        padding: 15px 0;

        thead {
            tr {
                background: #ff980040;
                height: 35px;

                th {
                    font-weight: 500;
                    font-size: 14px;

                    .header-label {
                        display: flex;
                        width: 100%;
                        align-items: center;
                        justify-content: center;
                        gap: 4px;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    text-align: center;
                    font-size: 14px;
                    padding: 5px 0;
                    border-bottom: 1px solid #9e9e9e54;

                    .view-text {
                        cursor: pointer;
                        background: transparent;
                        outline: transparent;
                        border: transparent;
                        color: #ff9800;
                        font-weight: 600;
                    }
                }

            }
        }
    }
}


.changes-details-wrapper {
    height: 75vh;

    .table-wrapper {
        display: block;
        height: 75vh;
        overflow: auto;

        .detailed-history-table {
            width: 100%;

            // padding: 15px 0;
            thead {
                tr {
                    background: #ff980040;
                    height: 35px;

                    th {
                        font-weight: 500;
                        font-size: 14px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        text-align: center;
                        font-size: 14px;
                        padding: 5px 0;
                        border-bottom: 1px solid #9e9e9e54;

                        .view-text {
                            cursor: pointer;
                            background: transparent;
                            outline: transparent;
                            border: transparent;
                            color: #ff9800;
                            font-weight: 600;
                        }
                    }

                }
            }
        }
    }
}

.close-button-container {
    position: absolute;
    right: 10px;

    .close-button {
        color: #FFF;
        border: 1px solid #ff8e33;
        background: #ff8e33;
        border-radius: 15px;
        cursor: pointer;
        margin: 10px;
    }
}