.legal-details-Header {
    height: 35px;
    margin: 0;
    box-shadow: 0px 1px 5px #00000029;
    background: #0000000d;
    display: flex;
    padding: 8px;
    align-items: center;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    p {
        font-weight: 600;
        color: #090a0c;
        padding: 0 5px;
    }
    .edit-delete-btn-wrapper {
        display: flex;
        .edit-btn-wrapper {
            text-decoration: none;
            color: #0073d3;
            font-size: 13px !important;
            font-weight: 500 !important;
            margin: 0 10px;
            cursor: pointer;
        }
    }
}
