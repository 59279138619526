.Sub-Reference-management-page {
    ::-webkit-scrollbar {
        display: none;
    }

    height: calc(100vh - 100px);
    overflow: auto;

    .Sub-Reference-table-layout {

        .sub-Reference-table-header {
            display: flex;
            flex-direction: column;
            height: 90px;

            .sub-Reference-table-header-options {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 10px;

                .sub-Reference-table-options {
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    height: 50px;
                }
            }

            .search-bar {
                padding-left: 5px;
                width: 285px;
                .search-box{
                    margin: 5px 0px !important; 
                }
            }

            .listed-products-selector {
                display: flex;
                align-items: center;

                span {
                    color: #8181818a;
                }

                &:nth-child(6) span {
                    display: none;
                }

                .inventorys-selector {
                    color: #090a0c;
                    font-size: 14px;
                    margin: 0 5px;
                    text-decoration: none;

                    .header-btn {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 10px 25px;
                        gap: 10px;
                        height: 40px;
                        border: 1px solid #E7E7E7;
                        border-radius: 10px;

                        .header-label {
                            width: 83px;
                            font-family: 'Roboto';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 150%;
                            text-align: center;
                            text-transform: uppercase;
                            color: #FB6C00;

                        }

                    }

                    &.active {
                        .header-btn {
                            display: flex;
                            flex-direction: row;
                            justify-content: center;
                            align-items: center;
                            padding: 10px 25px;
                            gap: 10px;
                            height: 40px;
                            border: 1px solid #E7E7E7;
                            border-radius: 10px;
                            background: #FFF1E6;
                            border: 1px solid #FFF1E6;

                        }
                    }


                    p {
                        padding: 8px 22px;
                        color: #818181;
                        font-size: 14px;
                    }

                    &.active {
                        p {
                            color: #090a0c;
                            border-bottom: 2px solid #ff6522;
                            background: #fff2ed;
                            border-radius: 10px 10px 0px 0px;
                            font-weight: bolder;
                        }
                    }
                }
            }
        }
    }
}