.btn-color-wrap {
    background: #ff8e33;
    font-family: "Lato";
    border: none;
    height: 30px;
    padding: 0px 15px;
    color: #fff;
    border-radius: 3px;
    cursor: pointer;
    width: auto;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    font-family: "Roboto";
    font-weight: 500;
}

.btn-outline-wrap {
    background: #fff;
    font-family: "Lato";
    outline: transparent;
    border: 1px solid #ff632b;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    height: 30px;
    padding: 0px 15px;
    color: #ff632b;
    border-radius: 3px;
    cursor: pointer;
    font-weight: 500;
    width: auto;
}
