.search-box {
    display: flex;
    align-items: center;
    background: #f1e9e2;
    padding: 5px;
    margin: 20px 0px;
    border-radius: 5px;

    input {
        width: 100%;
        border: unset;
        padding: 10px;
        background: #f1e9e2;
        outline: unset;
    }
    input::placeholder {
        font-size: 13px;
        font-weight: 400;
    }
}