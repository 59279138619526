.bajaj-info-table {
    padding: 8px 0px;
    width: 100%;

    .bajaj-info-manaagement-table {
        width: 100%;

        tr {
            width: 100%;
            th {
                padding: 0px 10px;
            }
        }

        .bajaj-info-manaagement-table-head {
            display: table;
            width: 100%;
            padding: 0px 20px;
            border-bottom: 1pt solid #FB6C00;;


            .bajaj-info-table-header-name{
                width: 30%;
            }
            .bajaj-info-table-header-supplier-id{
                width: 10%;
            }
            .bajaj-info-table-header-erp-prod-id{
                width: 10%;
            }
            .bajaj-info-table-header-stock{
                width: 10%;
            }
            .bajaj-info-table-header-status{
                width: 10%;
            }
            .bajaj-info-table-header-mrp{
                width: 10%;
            }
            .bajaj-info-table-header-updated-on{
                width: 10%;
            }
            .bajaj-info-table-header-action{
                display: flex;
                width: 100%;
                justify-content: center;

                p {
                    text-align: center;
                }
            }

            .bajaj-info-header {
                display: flex;
                align-items: center;
                height: 24px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                color: #FB6C00;
            }
        }

        .bajaj-info-manaagement-table-body {
            height: calc(100vh - 320px);
            overflow: auto;
            display: block;


            .action-btn {
                display: flex;
                justify-content: center;
            }

            
            tr {
                padding: 0px 20px;
                width: 100%;

                td {
                    padding: 0px 10px;
                }
            }

            .bajaj-info-manaagement-table-body-row {
                display: table;

                .bajaj-info-body-name{
                    width: 30%;
                }
                .bajaj-info-body-supplier-id{
                    width: 10%;
                }
                .bajaj-info-body-erp-prod-id{
                    width: 10%;
                }
                .bajaj-info-body-stock{
                    width: 10%;
                }
                .bajaj-info-body-status{
                    width: 10%;
                }
                .bajaj-info-body-mrp{
                    width: 10%;
                }
                .bajaj-info-body-updated-on{
                    width: 10%;
                }
                .bajaj-info-body-action{
                    width: 10%;

                    p {
                        text-align: center;
                    }
                }
            }

            .table-data-item {
                display: flex;
                overflow-wrap: anywhere;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #969494;
            }
        }
    }
}