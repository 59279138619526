.input-with-dropdown {
  height: 40px;
  margin-bottom: 20px;

  .label-filed-wrap {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
  }
  label {
    text-align: start;
    margin: 0 10px;
    font-size: 14px;
    font-weight: 500;
    font-family: "Roboto";
  }
  .input-wrap {
    border: 1px solid #cfcccc;
    border-radius: 3px;
    select {
      border: none;
      outline: none;
      width: 100%;
      height: 32px;
      padding: 0 10px;
    }
    select::placeholder {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .error-text-wrap {
    width: 100%;
    margin: 0;
    padding: 3px;
    font-size: 11px;
    text-align: right;
    color: rgb(253, 10, 10);
  }
}
