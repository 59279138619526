.Activelocations-page {
    width: 100%;
    display: flex;

    .warehouse-list-wrap {
        overflow-y: auto;
        height: calc(100vh - 190px);
        width: 80%;
    }


    .no-warehouse {
        text-align: center;
        display: flex;
        justify-content: center;
        height: 20%;
        width: 100%;
        align-items: center;

        .No-Active-card-wrapper {
            align-items: center;
            padding: 50px;
            background: #f2f2f2;
            width: 50%;
            border-radius: 15px;
        }
    }

    .warehouse-search {
        .search-box {
            margin: 0;
        }
    }

    .warehouse-search-btn {
        display: flex;
        padding-top: 10px;
        justify-content: space-between;
        gap: 10px;
    }

    .location-layout-wrapper {
        width: 798px;
        padding: 0 20px;
        margin-bottom: 20px;
        position: relative;

        .legal-details-Header {
            height: 35px;
            margin: 0;
            box-shadow: 0px 1px 1px #00000029;
            background: #0000000d;
            display: flex;
            padding: 8px;
            align-items: center;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            padding: 8px 11px;
            justify-content: space-between;

            p {
                font-weight: 600;
                color: #090a0c;
                padding: 0 5px;
            }

            .edit-delete-btn-wrapper {
                display: flex;

                .edit-btn-wrapper {
                    text-decoration: none;
                    color: #0073d3;
                    font-size: 12px;
                    font-weight: 400;
                    margin: 0 10px;
                    cursor: pointer;
                }
            }
        }

        .activelocation-details-card {
            background: #ffff;
            box-shadow: 0px 1px 5px #00000029;
            padding-bottom: 20px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 15px;

            .activelocation-details-content {
                display: flex;
                justify-content: space-around;
                align-items: flex-start;

                .warehouse-image-ic {
                    color: #d9d9d9;
                    width: 100px;
                    font-size: 100px;
                }

                .registered-address-content {
                    width: 200px;
                    line-height: 1.4;
                    overflow-wrap: break-word;
                }

                .primary-contact-content {
                    width: 180px;
                    line-height: 1.4;
                }

                .warehouse-details-label {
                    color: #818181;
                    margin: 3px 0;
                    font-size: 14px;
                }

                .warehouse-details-content {
                    margin: 0;
                    color: #000000;
                    font-size: 14px;
                    font-weight: 600;
                }
            }

            .see-listed-product {
                text-align: end;
                color: #0e7ad5;
                font-size: 12px;
                font-weight: 500;
                margin-bottom: 5px;
                text-decoration: none;
            }
        }
    }
}

.warehouse-delete-dialog {
    text-align: center;

    .delete-title {
        text-align: center;

        .MuiTypography-root {
            font-size: 15px;
            font-family: "Lato";
            font-weight: 600;
        }
    }

    .btn-action {
        background: #fff2ed;
        color: #f25822;
        font-size: 15px;
        font-weight: 600;

        font-family: "Lato";
        outline: transparent;
        border: none;
        height: 48px;
        padding: 0px 15px;
        border-radius: 3px;
        cursor: pointer;
        width: 160px;
        box-shadow: 0px 3px 6px #00000052;
    }

    .outlined {
        // background: #fff2ed;
        color: #f25822;
        font-size: 15px;
        font-weight: 600;

        font-family: "Lato";
        outline: transparent;
        border: none;
        height: 48px;
        padding: 0px 15px;
        border-radius: 3px;
        cursor: pointer;
        width: 160px;
        box-shadow: 0px 3px 6px #00000052;
    }
}