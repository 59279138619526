.layout {
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    .sidemenu-wrap {
        width: 10%;
    }
    .layout-header-wrapper {
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }
}
