.headers {
    height: 50px;
    display: flex;
    justify-content: end;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #dddddd;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

    .progress-status {
        display: flex;
        width: 30px;
        position: relative;
        left: -50px;
        top: 0px;
        padding: 0;
    }

    .profile {
        background-color: #ff8e33;
        border-radius: 25px;
        width: 30px;
        height: 30px;
        position: absolute;
        right: 15px;
    }

    .bck_btn {
        color: #ff8e33;
        margin: 0 10px;
        font-weight: 600;
        cursor: pointer;
    }

    .pop-up {
        background-color: #ff8e33;
        z-index: 999;
    }

    .MuiIconButton-root:hover {
        background-color: #ff8e33bf;
    }

    .btn-container {
        padding: 10px;
        cursor: pointer;
    }

    .progress-percentage {
        padding: 10px;
        width: 100%;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .progress-con {
            margin-left: 10px;
            width: 90%;
            display: flex;
            gap: 10px;
            align-items: center;

            .progress-label {
                color: #f5f5f5;
                text-transform: capitalize;
                font-size: 16px;
                width: 70%;
            }

            .progress-label-none {
                display: none;
            }

            .progress-result {
                color: #f5f5f5;
                cursor: pointer;
                text-transform: capitalize;
                font-size: 16px;
                width: 100%;
            }

            .progress-bar {
                width: 30%;
            }
        }
    }

}