.pagination-container {
    position: fixed;
    bottom: 0;
    right: 0;
    .pagination {
        display: flex;
        align-items: center;
        .input-with-dropdown {
            margin-bottom: unset;
            height: unset;
            width: 100%;
            max-width: 65px;
        }
        .label-filed-wrap {
            display: unset;
        }
        .MuiPagination-root {
            .MuiPagination-ul {
                float: right;
                margin: 20px;
                li {
                    .Mui-selected {
                        background-color: #ff8e33 !important;
                    }
                }
            }
        }
    }
}
