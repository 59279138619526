.inventory-management-page {
    padding: 30px;
    padding-left: 10px;
    background: #fbfbfb;
    height: calc(100vh - 100px);
    overflow: auto;

    .inventory-table-title {
        display: flex;
        align-items: center;
        grid-gap: 20px;
        gap: 20px;
        button {
            background: #59b32466;
            color: green;
            border: 0;
            width: 200px;
            height: 30px;
            border-radius: 5px;
            font-weight: 600;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1rem;
            .btn-text {
                margin-right: 1rem;
                padding-left: 10px;
            }
        }
        .upload-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            .txt{
                margin: 0 10px;
            }
        }
    }

    input[type="file"] {
        display: none;
    }
    .custom-file-upload {
        border: 1px solid #61aaff;
        display: inline-block;
        padding: 3px 12px;
        cursor: pointer;
        background: #61aaff;
        font-weight: 500;
        color: #fff;
        border-radius: 5px;
        height: 30px;
        margin: 0 14px;
        font-size: 14px;
    }

    .file-preview {
        margin: 0 10px;
    }
    .export-button-wrapper {
        background: #d9d9d9;
        color: #000000;
        border: 0;
        width: 100px;
        height: 30px;
        border-radius: 5px;
        font-weight: 600;
    }
    .Inventory-table-layout {
        margin: 30px 0;

        .Inventory-table-header {
            display: flex;
            align-items: center;
            justify-content: left;
            height: 40px;
            border: 1px solid #f5f5f5;
            background-color: #ffffff;
            box-shadow: 0px 1px 5px #00000029;
            .listed-products-selector {
                display: flex;
                align-items: center;
                span {
                    color: #8181818a;
                }
                &:nth-child(6) span {
                    display: none;
                }
                .inventorys-selector {
                    color: #090a0c;
                    font-size: 14px;
                    margin: 0 5px;
                    text-decoration: none;
                    p {
                        padding: 8px 22px;
                        color: #818181;
                        font-size: 14px;
                    }
                    &.active {
                        p {
                            color: #090a0c;
                            border-bottom: 2px solid #ff6522;
                            background: #fff2ed;
                            border-radius: 10px 10px 0px 0px;
                            font-weight: bolder;
                        }
                    }
                }
            }
            .inventory-filters {
                .inventory-selector {
                    color: #090a0c;
                    font-size: 14px;
                    margin: 0 20px;
                    padding: 12px;
                    border-bottom: 2px solid #ff6522;
                }
            }
            .inventory-products-search {
                margin: 0 auto;
                ::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    font-family: "Lato";
                    color: #b8b8b8;
                    font-size: 13px;
                }
                ::-moz-placeholder {
                    /* Firefox 19+ */
                    font-family: "Lato";
                }
                .products-search {
                    width: 348px;
                    height: 25px;
                    border: 1px solid #b8b8b8;
                }
            }
            .products-filters {
                display: flex;
                align-items: center;
                margin: 0 20px;
                width: 25%;
                // width: 25%;
                justify-content: space-between;
                .status-dropdown {
                    border: 0.5px solid #f5f5f5;
                    background: #fafafa;
                    padding: 5px;
                    margin: 0 15px;
                    color: #818181;
                    font-size: 14px;
                    font-family: "Lato";
                    outline: none;
                }
                .sort-btn {
                    display: flex;
                    align-items: center;
                    background: #fafafa;
                    border: 0.5px solid #f5f5f5;
                    color: #818181;
                    padding: 5px;
                    width: 60px;
                    justify-content: center;
                    .sort-ic {
                        width: 15px;
                        color: #818181;
                    }
                }
                p {
                    color: #090a0c;
                    font-family: "Lato";
                    font-size: 14px;
                    font-weight: 800;
                    padding: 5px 8px;
                }
            }
        }
    }

    .inventory-manaagement-table {
        position: relative;
        border: 1px solid #f5f5f5;
        background-color: #ffffff;
        box-shadow: 0px 1px 5px #00000029;
        width: 100%;
        // height: calc(100vh - 300px);
        // overflow-y: auto;

        .sort-ic-th {
            color: #5d4848;
        }
        th,
        td {
            border-right: none;
            border-left: none;
        }
        th {
            padding: 20px 10px;
            color: #090a0c;
            font-size: 13px;
            font-weight: 800;
            top: 0px;
            z-index: 1;
            text-align: center;
            font-family: "Lato";
            border: 0;
            background-color: transparent;
        }

        .product-col {
            display: flex;

            .pimg-wrap {
                width: 65px;
                height: 65px;
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #ddd;
                margin-right: 19px;
                img.product-img {
                    width: auto;
                    height: 50px;
                    object-fit: contain;
                }
            }

            .pcontent-wrap {
                display: flex;
                align-items: center;
                justify-content: left;
                line-height: 20px;
                word-break: break-word;
                color: #121212;
            }
        }
        .status-col {
            .badge-wrap {
                display: inline-flex;
                padding: 4px 10px;
                background: #f2fff2;
                border: 0.5px solid #41b13d;
                border-radius: 100px;
                color: #606060;
                font-size: 11px;
            }
        }
        .add-stock-remove-action {
            .btn-primary-edit {
                background: #fff2ed;
                box-shadow: 0px 3px 6px #00000052;
                border-radius: 6px;
                color: #fb6c00;
                margin: 10px 0;
                padding: 5px 0px;
            }
            .dialogContent {
                .stock-label-content {
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }
    .all-products-pagination {
        margin-top: 50px;
        .MuiPagination-ul {
            justify-content: flex-end;
        }
    }
    // .bulk-action-text {
    //     margin: 0;
    //     padding: 12px;
    //     color: #090a0c;
    //     font-size: 14px;
    //     font-weight: bold;
    //     font-family: "Lato";
    // }
}
.inventory-filter-dialog {
    .input-with-dropdown .txt-label {
        font-size: 14px;
        font-family: "Lato";
        color: #898989;
        width: 65%;
        text-align: left;
        margin-right: 0;
    }
}
