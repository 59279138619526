.productDetails-cards {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    max-width: 250px;
    .banner {
        display: flex;
        justify-content: center;
        cursor: pointer;
        margin-bottom: 0;
        padding-top: 100%;
        position: relative;
        img {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
            max-width: 100%;
            max-height: 100%;
            vertical-align: top;
        }
    }
    .card-description {
        padding-bottom: 24px;
        padding: 16px;
        text-align: inherit;
        border-top: 1px solid #ff8e33;
        display: flex;
        .product-name {
            font-weight: 600;
        }
        .view-btn {
            display: flex;
            align-items: center;
        }
    }
}
