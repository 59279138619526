.register-form-wrap {
    .sign-in-content {
        font-size: 24px;
        font-weight: 600;
        color: #555555;
        margin: 15px 0px 5px 0px;
    }
    .access-acc-content {
        font-size: 18px;
        font-weight: 400;
        color: #555555;
        margin: 10px 0px;
    }
    .blue-content {
        color: #2874f0;
        font-weight: 500;
        cursor: pointer;
    }
    .login-form-wrap {
        text-align: center;
        width: 100%;
        margin: 0 auto;
        .form-body {
            display: flex;
            align-items: center;
            flex-direction: column;
            .input-label-wrapper{
                max-width: 350px;
                width: 80%;
                .input-img-wrapper {
                    max-width: 350px;
                    width: 100%;
                }
                input {
                    width: 100%;
                }
            }
        }

        .conditions-content {
            font-size: 14px;
            font-weight: 400;
            text-align: center;
        }

        .form-btn-wrap {
            width: 300px;
            margin: auto;
            .btn-color-wrap {
                height: 40px;
                font-size: 16px;
            }
            .forgot-password {
                color: #fb6c00;
                font-size: 16px;
                font-weight: 500;
                text-align: end;
                cursor: pointer;
            }
        }
    }
    .soo-login-btn {
        width: 150px;
        margin: 10px auto;
    }
}
