    .upload-icon {
        padding: 0 5px;
    }

    .Addlocations-page {
        .handle-history-ic{
            display: flex;
            justify-content: flex-end;
            padding: 0px 20px 10px 20px;
            width: 100%;
            img{
                width: 24px;
                cursor: pointer;
            }
        }
        .location-layout-wrapper {
            padding: 0px 30px 30px 30px;
            background: #ffffff;
            display: flex;
            height: calc(100vh - 100px);
            overflow: auto;

            .location-core-details {
                width: 100%;

                .legal-details-content {
                    background: #ffff;
                    box-shadow: 0px 1px 5px #00000029;
                    padding-bottom: 20px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 0 8px 20px;

                    .courier-fullfilment-content {
                        padding: 5px 15px;
                        line-height: 2;

                        .text-label-content {
                            right: 10px;
                            position: absolute;

                            &:focus {
                                background-color: #fff7f0;
                                border: 1px solid #ffddbc;
                                outline: none;

                                .hint-text {
                                    display: none;
                                }
                            }
                        }

                        .input-with-verified-icon {
                            position: relative;
                            display: flex;
                            padding: 5px;
                            border-radius: 2px;
                            border: 1px solid #e8e9ec;
                            font-family: "Lato";
                            width: 100%;
                            min-height: 66px;
                            border-radius: 4px;
                            box-sizing: border-box;
                            height: auto;

                            .days-wrapper {
                                display: grid !important;
                                grid-template-columns: 53.33% 53.33% 53.33% !important;
                                column-gap: 2px !important;
                                font-size: 12px !important;
                                color: #818181;

                                p {
                                    margin: 0;
                                }
                            }
                        }

                        .verified-icon {
                            color: #b8b8b8;
                            right: 0.65vw;
                            position: absolute;
                            width: 14px;
                            top: 4px;
                            cursor: pointer;
                        }
                    }

                    .registed-address-content {
                        padding: 5px 15px;
                        line-height: 1.3;

                        ::-webkit-scrollbar {
                            width: 0px;
                        }

                        .address-field {
                            border: 1px solid #e8e9ec;
                            height: 120px;
                            position: relative;
                            margin-top: 5px;
                            border-radius: 5px;
                            padding: 10px;
                            overflow: scroll;
                            min-height: 150px;

                            p {
                                margin: 0;
                                color: #b8b8b8;
                                font-size: 14px;
                                padding-right: 40px;
                            }

                            .verified-icon {
                                position: absolute;
                                color: #b8b8b8;
                                width: 15px;
                                right: 20px;
                                top: 14px;
                                cursor: pointer;
                            }
                        }

                        label {
                            font-size: 14px;
                            color: #818181;
                        }
                    }

                    .input-with-dropdown-down {
                        line-height: 2;
                        padding: 15px;

                        .txt-label {
                            font-size: 14px;
                            font-family: "Lato";
                            color: #898989;
                            text-align: left;
                        }

                        .input-and-error {
                            width: 100%;
                        }

                        .input-field-box {
                            padding: 6px;
                            border-radius: 2px;
                            font-family: "Lato";
                            width: 100%;
                            height: 36px;
                            border: 1px solid #e8e9ec;
                        }

                        .input-field-box-error {
                            padding: 6px;
                            border-radius: 2px;
                            border: 1px solid #e8e9ec;
                            font-family: "Lato";
                            width: 100%;
                            height: 36px;
                        }
                    }

                    .working-days-content {
                        padding: 5px 15px;
                        line-height: 2;

                        .text-label-content {
                            right: 10px;
                            position: absolute;

                            &:focus {
                                background-color: #fff7f0;
                                border: 1px solid #ffddbc;
                                outline: none;

                                .hint-text {
                                    display: none;
                                }
                            }
                        }

                        .input-with-verified-icon {
                            position: relative;
                            display: flex;
                            padding: 5px;
                            border-radius: 2px;
                            border: 1px solid #e8e9ec;
                            font-family: "Lato";
                            width: 100%;
                            height: 66px;
                            border-radius: 4px;
                            box-sizing: border-box;
                            height: auto;

                            .days-wrapper {
                                display: grid;
                                grid-template-columns: 33.33% 33.33% 33.33%;
                                column-gap: 5px;
                                font-size: 14px;
                                color: #818181;

                                p {
                                    margin: 0;
                                }
                            }
                        }

                        .verified-icon {
                            color: #b8b8b8;
                            right: 0.65vw;
                            position: absolute;
                            width: 14px;
                            top: 12px;
                            cursor: pointer;
                        }
                    }
                }
            }

            .warehouse-contacts-wrapper {
                width: 100%;

                .legal-details-content {
                    background: #ffff;
                    box-shadow: 0px 1px 5px #00000029;
                    padding-bottom: 20px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 0 8px 20px;
                    margin-bottom: 25px;

                    .contacts-label {
                        color: #100f0f;
                        font-size: 14px;
                        margin: 0;
                        padding-top: 10px;
                        font-weight: 500;
                    }
                }
            }

            .warehouse-channels-wrapper {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;

                .warehouse-channels-header-wrapper {
                    display: flex;
                    width: 100%;
                    margin-bottom: 15px;
                    align-items: center;
                    position: relative;

                    .add-channel-ic {
                        position: absolute;
                        color: #ff8e33;
                        right: 0;
                        cursor: pointer;
                    }
                }

                .channels-details-content {
                    width: 100%;
                    display: flex;
                    background: #ffff;
                    box-shadow: 0px 1px 5px #00000029;
                    padding-bottom: 20px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 10px 0px 20px 0px;
                    margin-bottom: 25px;
                    flex-direction: column;

                    .contacts-label {
                        color: #100f0f;
                        font-size: 14px;
                        margin: 0;
                        padding-top: 10px;
                        font-weight: 500;
                    }
                }

                .channels-details-btn {
                    padding-top: 30px;
                    display: flex;
                    align-items: center;
                }

                .channels-details-content2 {
                    width: 100%;
                    display: flex;
                    background: #ffff;
                    padding-bottom: 20px;
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    padding: 10px 0px 0px 0px;
                    margin-bottom: 10px;

                    .contacts-label {
                        color: #100f0f;
                        font-size: 14px;
                        margin: 0;
                        padding-top: 10px;
                        font-weight: 500;
                    }
                }
            }

            .addwarehouse-submit-btn {
                text-align: center;
                margin: 20px 0;

                .btn-color-wrap {
                    width: 150px;
                }
            }

            .error-text {
                text-align: left;
            }
        }

        .autocomplete {
            .txt-label {
                margin: 0;
                font-size: 14px;
                color: #818181;
                display: flex;
                align-items: center;
            }

            .error-text {
                width: 100%;
                margin: 0;
                padding: 3px;
                font-size: 11px;
                text-align: left;
                color: red;
                font-family: "Lato";
            }
        }
    }