.input-label-wrapper {
    margin-bottom: 20px;
    height: 40px;
    .input-img-wrapper {
        width: 350px;
        border-bottom: 1px solid #cfcccc;
        display: flex;
        img {
            width: 25px;
            height: 25px;
        }
    }
    input {
        border: none;
        outline: none;
        width: 300px;
        height: 32px;
        padding: 0 10px;
    }
    input::placeholder {
        font-size: 16px;
        font-weight: 400;
    }
    .error-text-wrap {
        width: 100%;
        margin: 0;
        padding: 3px;
        font-size: 11px;
        text-align: left;
        color: rgb(253, 10, 10);
    }
}
