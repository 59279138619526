.productDetails-cards {
  display: flex;
  width: 100%;
  max-width: 100%;
  background: #fabd8b;
  align-items: center;




  .not-clickable {
    pointer-events: auto !important;
    cursor: not-allowed !important;
    background-color: #bcbfbf !important;
    box-shadow: none !important;
  }

  .blue {
    .count-icon {
      .circle-layer {
        background-color: #1c64f275;

        .dashboard-card-icon {
          color: #1c64f2;
        }
      }
    }
  }

  .brown {
    .count-icon {
      .circle-layer {
        background-color: #feecdc;

        .dashboard-card-icon {
          color: #da873c;
        }
      }
    }
  }

  .green {
    .count-icon {
      .circle-layer {
        background-color: rgb(110 230 186 / 85%);

        .dashboard-card-icon {
          color: rgb(20 136 31);
        }
      }
    }
  }

  .red {
    .count-icon {
      .circle-layer {
        background-color: #e21010;

        .dashboard-card-icon {
          color: #fff;
        }
      }
    }
  }

  .icon-area {}

  .blue {
    .icon-area {
      .circle-layer {
        background-color: #1c64f275;

        .dashboard-card-icon {
          color: #1c64f2;
        }
      }
    }
  }

}

img {
  max-width: 100%;
  max-height: 100%;
}

.productDetails-cards {
  display: flex;
  width: 100%;
  max-width: 100%;
  background: #fabd8b;
  align-items: center;
  padding: 20px 10px;

  .image {
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 50px;
    margin-left: 20px;
    width: 5%;

    img {
      width: 50px;
      height: 65px;
    }
  }

  .product-name-wrapper {
    display: flex;
    width: 20%;
    flex-direction: column;

  }

  .count-description {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 5px 0 5px 15px;
    font-size: 14px;
    font-weight: 400;
  }

  .count-wrapper {
    width: 70%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .count-card {
      cursor: pointer;
      border: 1px solid #b7b0b0;
      min-width: 150px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #eee;
      border-radius: 20px;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);

      .count-icon {
        width: 25%;

        .circle-layer {
          // background-color: #feecdc;
          width: 50px;
          height: 50px;
          justify-content: center;
          align-items: center;
          display: flex;
          border-radius: 50px;
          margin: 10px;

          .dashboard-card-icon {
            font-size: 25px;
            // color: #da873c;
          }
        }
      }
      

      .count-area {
        width: 75%;



        .count {
          display: flex;
          justify-content: center;
          padding-left: 15px;
          margin: 0;
          font-size: 15px;
        }
      }
    }
    

  }
}