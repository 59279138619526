.bajaj-seller-table {
    padding: 8px 0px;
    width: 100%;

    .bajaj-seller-manaagement-table {
        width: 100%;

        tr {
            th {
                padding: 0px 10px;
            }
        }

        .bajaj-seller-manaagement-table-head {
            display: table;
            width: 100%;
            padding: 0px 20px;
            border-bottom: 1pt solid #FB6C00;

            .bajaj-seller-table-header-supplier-id {
                width: 10%;
            }

            .bajaj-seller-table-header-supplier-desc {
                width: 30%;
            }

            .bajaj-seller-table-header-city-id {
                width: 8%;
            }

            .bajaj-seller-table-header-address {
                width: 32%;
            }

            .bajaj-seller-table-header-action {
                display: flex;
                width: 100%;
                justify-content: center;

                p {
                    text-align: center;
                }
            }

            .table-data-header {
                display: flex;
                align-items: center;
                height: 24px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                color: #FB6C00;
            }
        }

        .Sub-inventory-manaagement-table-body {
            height: calc(100vh - 320px);
            overflow: auto;
            display: block;

            // height: calc(100vh - 300px);
            // overflow-y: auto;
            .action-btn {
                display: flex;
                justify-content: center;
            }

            tr {
                padding: 0px 20px;
                width: 100%;

                td {
                    padding: 0px 10px;
                }
            }

            .inventory-manaagement-table-body-row {
                display: table;


                .bajaj-seller-table-body-supplier-id {
                    width: 10%;
                }

                .bajaj-seller-table-body-supplier-desc {
                    width: 30%;
                }

                .bajaj-seller-table-body-city-id {
                    width: 8%;
                }

                .bajaj-seller-table-body-address {
                    width: 32%;
                }

                .bajaj-seller-table-body-action {
                    width: 20%;

                    p {
                        text-align: center;
                    }
                }

            }

            .table-data-item {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #969494;
            }
        }
    }
}