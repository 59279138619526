.sidebar {
  width: 180px;
  background: #ff8e33;
  height: 100vh;

  .sidebar-header {
    .logo {
      padding: 9px;
      border-bottom: 2px solid #fb6c004a;
    }
  }
}

.admin-menu:hover {

  .admin-sub-menu {
    border-radius: 5px;
    box-sizing: border-box;
    background: #ff8e33;
    top: -50px;
    left: 100%;
    position: relative;
    display: block;
  }
}

.admin-menu:not(:hover) {
  .admin-sub-menu {
    display: none;
  }
}

.app-sidebar:not(:hover) {
  height: 100vh;
  transition: width 0.2s;
  transition-timing-function: linear;
  position: relative;
  background: #ff8e33;
  width: 50px;
  z-index: 1000;

  .menu-header {
    // position: relative;
    padding: 5px 0px;
    // margin-left: 5px;
    display: flex;
    border-bottom: 0.5px solid #fff;
    justify-content: center;

    img {
      height: auto;
      width: 80%;
      object-fit: contain;
      height: 35px;

      &.menu-color-logo {
        display: none;
      }

      &.menu-logo {
        display: block;
      }
    }
  }

  .menu-list {
    position: relative;
    margin-top: 30px;
    height: 67vh;

    .admin-seller-listmenu {
      .side-menu-item {
        position: relative;
        padding-left: 10%;
        margin-bottom: 5px;
        .menu-link {
          display: flex;
          align-items: center;
          text-decoration: none;
          color: #818181;
  
          &.active {
            display: flex;
            width: 100%;
            background: #FFFFFF;
            border-bottom-left-radius: 10px;
            border-top-left-radius: 10px;
            justify-content: center;
            color: #ff8e33 !important;
  
            .menu-ic {
              //padding: 10px 12px;
  
              img {
                &.menu-img {
                  display: none;
                  color: #818181;
                }
  
                &.menu-color-img {
                  display: block;
                }
              }
            }
          }
  
          &.active::before {
            content: "" !important;
            position: absolute !important;
            top: -6px !important;
            right: 0 !important;
            width: 15px !important;
            height: 15px !important;
            border-bottom-right-radius: 20px !important;
            box-shadow: 7.5px 7.5px 0 7.5px #fff !important;
          }
  
          &.active::after {
            content: "";
            position: absolute;
            bottom: -6px;
            right: 0;
            width: 15px;
            height: 15px;
            border-top-right-radius: 20px;
            box-shadow: 7.5px -7.5px 0 7.5px #fff;
          }
  
          &:hover {
            background: #e1dbdb;
            border-radius: 10px;
            color: #090a0c;
          }
  
          .menu-ic {
            padding: 10px 0px;
  
            img {
              width: 100%;
  
              &.menu-color-img {
                display: none;
              }
            }
          }
  
          .menu-title {
            display: none;
          }
  
          .menu-title-none {
            display: none;
          }
        }
        

        &.cursor-notAllow {
          cursor: not-allowed;
        }
      }

      .disabled-link {
        pointer-events: none;
      }
    }

    .side-menu-item {
      position: relative;

      padding: 8px 0px 8px 8px;

      .menu-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #818181;

        &.active {
          display: flex;
          width: 100%;
          background: #FFFFFF;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          justify-content: center;
          color: #ff8e33 !important;

          .menu-ic {
            //padding: 10px 12px;

            img {
              &.menu-img {
                display: none;
                color: #818181;
              }

              &.menu-color-img {
                display: block;
              }
            }
          }
        }

        &.active::before {
          content: "" !important;
          position: absolute !important;
          top: -6px !important;
          right: 0 !important;
          width: 15px !important;
          height: 15px !important;
          border-bottom-right-radius: 20px !important;
          box-shadow: 7.5px 7.5px 0 7.5px #fff !important;
        }

        &.active::after {
          content: "";
          position: absolute;
          bottom: -6px;
          right: 0;
          width: 15px;
          height: 15px;
          border-top-right-radius: 20px;
          box-shadow: 7.5px -7.5px 0 7.5px #fff;
        }

        &:hover {
          background: #e1dbdb;
          border-radius: 10px;
          color: #090a0c;
        }

        .menu-ic {
          padding: 10px 0px;

          img {
            width: 100%;

            &.menu-color-img {
              display: none;
            }
          }
        }

        .menu-title {
          display: none;
        }

        .menu-title-none {
          display: none;
        }
      }
    }
    .admin-side-menu-item {
      position: relative;

      padding: 8px 0px 8px 8px;

      .menu-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #818181;

        &.active {
          display: flex;
          width: 100%;
          background: #FFFFFF;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          justify-content: center;
          color: #ff8e33 !important;

          .menu-ic {
            //padding: 10px 12px;

            img {
              &.menu-img {
                display: none;
                color: #818181;
              }

              &.menu-color-img {
                display: block;
              }
            }
          }
        }

        &.active::before {
          content: "" !important;
          position: absolute !important;
          top: -6px !important;
          right: 0 !important;
          width: 15px !important;
          height: 15px !important;
          border-bottom-right-radius: 20px !important;
          box-shadow: 7.5px 7.5px 0 7.5px #fff !important;
        }

        &.active::after {
          content: "";
          position: absolute;
          bottom: -6px;
          right: 0;
          width: 15px;
          height: 15px;
          border-top-right-radius: 20px;
          box-shadow: 7.5px -7.5px 0 7.5px #fff;
        }

        &:hover {
          //background: #e1dbdb;
          border-radius: 10px;
          color: #090a0c;
        }

        .menu-ic {
          padding: 10px 0px;

          img {
            width: 100%;

            &.menu-color-img {
              display: none;
            }
          }
        }

        .menu-title {
          display: none;
        }

        .menu-title-none {
          display: none;
        }
      }
    }
  }

  .progress-percentage {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .progress-con {
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: center;

      .progress-label {
        display: none;
      }

      .progress-label-none {
        display: none;
      }

      .progress-result {
        color: #f5f5f5;
        cursor: pointer;
        text-transform: capitalize;
        font-size: 16px;
        width: 100%;
      }

      .progress-bar {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}

.app-sidebar:hover {
  height: 100vh;
  transition: width 0.2s;
  transition-timing-function: linear;
  position: relative;
  background: #ff8e33;
  width: 250px;
  z-index: 1000;

  .menu-header {
    position: relative;
    padding: 7px 20px;
    border-bottom: 0.5px solid #fff;

    img {
      height: auto;
      width: 100%;
      object-fit: contain;
      height: 35px;

      &.menu-color-logo {
        display: block;
      }

      &.menu-logo {
        display: none;
      }
    }
  }

  .menu-list {
    position: relative;
    margin-top: 30px;
    height: 60vh;

    .admin-seller-listmenu {
      .side-menu-item {
        position: relative;
        padding-left: 10%;
        margin-bottom: 5px;

        &.cursor-notAllow {
          cursor: not-allowed;
        }
      }

      .disabled-link {
        pointer-events: none;
      }
    }

    .side-menu-item {
      position: relative;
      padding: 8px 0px 8px 8px;

      .menu-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #818181;

        &.active {
          background: #FFFFFF;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          color: #ff8e33 !important;


          .menu-ic {
            padding: 10px 12px;

            img {
              &.menu-img {
                display: none;
                color: #818181;
              }

              &.menu-color-img {
                display: block;
              }
            }
          }
        }

        &:hover::before {
          content: "";
          position: absolute;
          top: -6px;
          right: 0;
          width: 15px;
          height: 15px;
          border-bottom-right-radius: 20px;
          box-shadow: 7.5px 7.5px 0 7.5px #fff;
        }

        &:hover::after {
          content: "";
          position: absolute;
          bottom: -6px;
          right: 0;
          width: 15px;
          height: 15px;
          border-top-right-radius: 20px;
          box-shadow: 7.5px -7.5px 0 7.5px #fff;
        }

        &.active::before {
          content: "" !important;
          position: absolute !important;
          top: -6px !important;
          right: 0 !important;
          width: 15px !important;
          height: 15px !important;
          border-bottom-right-radius: 20px !important;
          box-shadow: 7.5px 7.5px 0 7.5px #fff !important;
        }

        &.active::after {
          content: "";
          position: absolute;
          bottom: -6px;
          right: 0;
          width: 15px;
          height: 15px;
          border-top-right-radius: 20px;
          box-shadow: 7.5px -7.5px 0 7.5px #fff;
        }

        &:hover {
          background: #fff;
          border-bottom-left-radius: 10px;
          border-top-left-radius: 10px;
          color: #ff8e33 !important;


          .menu-ic {
            padding: 10px 12px;

            img {
              &.menu-img {
                display: none;
                color: #818181;
              }

              &.menu-color-img {
                display: block;
              }
            }
          }
        }

        .menu-ic {
          padding: 10px 12px;

          img {
            width: 100%;

            &.menu-color-img {
              display: none;
            }
          }
        }

        .menu-title {
          font-size: 14px;
          text-transform: capitalize;
          padding-bottom: 5px;
        }

        .menu-title-none {
          display: none;
        }
      }

      .menu-link>p {}
    }
    .admin-side-menu-item {
      position: relative;
      padding: 8px 0px 8px 8px;

      .menu-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #fdfdfd;

        // &.active {
        //   background: #FFFFFF;
        //   border-bottom-left-radius: 10px;
        //   border-top-left-radius: 10px;
        //   color: #ff8e33 !important;


        //   .menu-ic {
        //     padding: 10px 12px;

        //     img {
        //       &.menu-img {
        //         display: none;
        //         color: #818181;
        //       }

        //       &.menu-color-img {
        //         display: block;
        //       }
        //     }
        //   }
        // }

        // &:hover {
        //   background: #fff;
        //   border-bottom-left-radius: 10px;
        //   border-top-left-radius: 10px;
        //   color: #ff8e33 !important;


        //   .menu-ic {
        //     padding: 10px 12px;

        //     img {
        //       &.menu-img {
        //         display: none;
        //         color: #818181;
        //       }

        //       &.menu-color-img {
        //         display: block;
        //       }
        //     }
        //   }
        // }

        .menu-ic {
          padding: 10px 12px;

          img {
            width: 100%;

            &.menu-color-img {
              display: none;
            }
          }
        }

        .menu-title {
          font-size: 14px;
          color: #fff;
          text-transform: capitalize;
          padding-bottom: 5px;
        }

        .menu-title-none {
          display: none;
        }
      }

      .menu-link>p {}
    }
  }

  .progress-percentage {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .progress-con {
      margin-left: 10px;
      width: 90%;
      display: flex;
      gap: 10px;
      align-items: center;

      .progress-label {
        color: #f5f5f5;
        text-transform: capitalize;
        font-size: 16px;
        width: 70%;
      }

      .progress-label-none {
        display: none;
      }

      .progress-result {
        color: #f5f5f5;
        cursor: pointer;
        text-transform: capitalize;
        font-size: 16px;
        width: 100%;
      }

      .progress-bar {
        width: 30%;
      }
    }
  }
}