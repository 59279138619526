.input-label-content {
    padding: 15px;
    line-height: 1.5;
    .text-label {
        margin: 0;
        font-size: 14px;
        color: #818181;
    
    }
    .text-label-content {
        margin: 0;
        font-size: 14px;
        color: #000000;
        font-weight: 600;
        word-break: break-word;
    }
}
