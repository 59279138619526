.suppliers-list-page {
    .inventory-table {
        .btn-wrapper {
            width: fit-content;
            margin: 20px 0;
        }

        .table-wrapper {
            margin: 30px;
        }

        .table-container {
            height: calc(100vh - 190px);
            overflow-y: auto;

            .inventory-list-table {
                padding: 25px;
                width: 100%;
                border: 1px solid #f5f5f5;
                background-color: #fff;
                box-shadow: 0 1px 5px rgb(0 0 0 / 16%);

                thead {
                    position: sticky;
                    top: 0;
                    background: #fff;
                }

                .action-btn-wrap {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    column-gap: 10px;

                    button {
                        max-width: 100px;
                        margin: 0 10px;
                    }
                }

                th,
                td {
                    border-right: none;
                    border-left: none;
                }

                td {
                    padding: 20px;
                    color: #636363;
                    font-size: 13px;
                    font-weight: 400;
                    z-index: 1;
                    text-align: center;
                }

                th {
                    padding: 20px 10px;
                    color: #090a0c;
                    font-size: 15px;
                    font-weight: 600;

                    top: 0;
                    z-index: 1;
                    text-align: center;
                    font-family: "Roboto";
                    border: 0;
                    background-color: transparent;
                }

                th:first-child,
                th:nth-child(2) {
                    width: auto !important;
                }

                td {
                    .btn-action {
                        margin: 0 !important;
                        height: 30px;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    .delete-Popup {
        .MuiDialogTitle-root {
            display: flex;
            justify-content: center;
            color: #ff0000;

            .MuiSvgIcon-root {
                font-size: 40px;
            }
        }
    }
}

.add-user-dialog {
    .adduser-form-wrap {
        padding: 20px;

        .header-wrap {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;

            .title-content {
                margin: auto;
            }
        }

        .form-body {
            .roles-dropdown {
                color: aqua;

                .input-with-dropdown .label-filed-wrap {
                    display: none;
                }
            }

            .generator {
                display: flex;
                padding: 0px 10px 20px 10px;
                cursor: pointer;
            }
        }
    }
}

.adduser-form-wrap {
    padding: 20px;

    .header-wrap {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;

        .title-content {
            margin: auto;
        }
    }

    .form-body {
        .field {
            display: flex;

        }

        .roles-dropdown {
            color: aqua;

            .input-with-dropdown .label-filed-wrap {
                display: block;

                .txt-label {
                    display: none;
                }

                .input-wrap {
                    border: none;
                    border-bottom: 1px solid #cfcccc;
                }
            }
        }
    }

    .form-btn-wrap {
        text-align: center;
    }
}