.product-list-page {
    .header {
        margin: 20px 50px 0px 30px;
        display: flex;
        justify-content: space-between;
        .product-Details {
            display: flex;
            .fields {
                padding: 0px 10px;
                span {
                    font-size: 15px;
                    font-weight: 600;
                    top: 0;
                    z-index: 1;
                    text-align: center;
                    font-family: "Roboto";
                    border: 0;
                    background-color: transparent;
                }
                .name {
                    color: #090a0c;
                    padding-right: 10px;
                }
                .value {
                    color: #ff8e33;
                }
            }
        }
        .custom-file-upload {
            /* border: 1px solid #61aaff; */
            display: inline-block;
            padding: 0px 10px;
            cursor: pointer;
            background: #61aaff;
            font-weight: 500;
            color: #fff;
            border-radius: 5px;
            height: 30px;
            margin: 0 13px;
            font-size: 11px;
            .upload-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                .txt {
                    margin: 0 10px;
                }
            }
        }
        input[type="file"] {
            display: none;
        }
    }
    .btn-wrapper {
        width: 150px;
        margin: 20px 0;
    }
    .table-wrapper {
        margin: 20px 30px;
    }
    .table-container {
        height: calc(100vh - 190px);
        overflow-y: auto;
        .product-list-table {
            padding: 2px 25px;
            width: 100%;
            border: 1px solid #f5f5f5;
            background-color: #fff;
            box-shadow: 0 1px 5px rgb(0 0 0 / 16%);
            thead {
                position: sticky;
                top: 0;
                background: #fff;
            }
            .status {
                display: inline-flex;
                align-items: center;
                display: inline-block;
                padding: 0.325rem 0.5rem;
                font-size: 0.85rem;
                font-weight: 600;
                line-height: 1;
                text-align: center;
                white-space: nowrap;
                vertical-align: baseline;
                border-radius: 0.325rem;
            }

            .NEW {
                color: #50cd89;
                background-color: #e8fff3;
            }
            .SOLD {
                color: #bb971a;
                background-color: #fff8dd;
            }
            .DEFECT {
                color: #ff0000;
                background-color: #fff5f8;
            }
            .IN_TRANSIT {
                color: #009ef7;
                background-color: #f1faff;
            }

            .action-btn-wrap {
                display: grid;
                grid-template-columns: 50% 50%;
                column-gap: 10px;
                button {
                    max-width: 100px;
                    margin: 0 10px;
                }
            }
            th,
            td {
                border-right: none;
                border-left: none;
            }
            td {
                padding: 20px;
                color: #636363;
                font-size: 13px;
                font-weight: 400;
                z-index: 1;
                text-align: center;
            }
            th {
                padding: 20px 10px;
                color: #090a0c;
                font-size: 15px;
                font-weight: 600;

                top: 0;
                z-index: 1;
                text-align: center;
                font-family: "Roboto";
                border: 0;
                background-color: transparent;
            }
            th:first-child,
            th:nth-child(2) {
                width: auto !important;
            }
            td {
                .btn-action {
                    margin: 0 !important;
                    height: 30px;
                    text-transform: uppercase;
                }
            }
        }
    }
    .pagination-container {
    }
}
