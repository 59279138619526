.dashboard-main {
    display: flex;
    height: 100vh;
    width: 100%;
    align-items: center;
    flex-direction: column;
    row-gap: 20px;

    .search {
        width: 50%;

        .search-btn {
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 0 180px;
        }
    }

    .inventory-table {
        .btn-wrapper {
            width: fit-content;
            margin: 20px 0;
        }

        .table-wrapper {
            margin: 30px;

            .header-wrapper {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .table-container {
            height: calc(100vh - 300px);
            overflow-y: auto;

            .inventory-list-table {
                padding: 25px;
                width: 100%;
                border: 1px solid #f5f5f5;
                background-color: #fff;
                box-shadow: 0 1px 5px rgb(0 0 0 / 16%);

                thead {
                    position: sticky;
                    top: 0;
                    background: #fff;
                }

                .action-btn-wrap {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    column-gap: 10px;

                    button {
                        max-width: 100px;
                        margin: 0 10px;
                    }
                }

                th,
                td {
                    border-right: none;
                    border-left: none;
                }

                td {
                    padding: 20px;
                    color: #636363;
                    font-size: 13px;
                    font-weight: 400;
                    z-index: 1;
                    text-align: center;
                }

                th {
                    padding: 20px 10px;
                    color: #090a0c;
                    font-size: 15px;
                    font-weight: 600;
                    top: 0;
                    z-index: 1;
                    text-align: center;
                    font-family: "Roboto";
                    border: 0;
                    background-color: transparent;

                    span {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        justify-content: center;
                    }
                }

                td {
                    .btn-action {
                        margin: 0 !important;
                        height: 30px;
                        text-transform: uppercase;
                    }
                }

                .profile {
                    align-items: center;
                    padding: 0.325rem 0.5rem;
                    font-size: 0.85rem;
                    font-weight: 600;
                    line-height: 1;
                    text-align: center;
                    white-space: nowrap;
                    border-radius: 0.325rem;
                    text-transform: capitalize;
                }

                .online {
                    color: #50cd89;
                    background-color: #e8fff3;
                }

                .shop {
                    color: #bb971a;
                    background-color: #fff8dd;
                }

                .seller {
                    color: #ff0000;
                    background-color: #fff5f8;
                }

                .drop_ship {
                    color: #009ef7;
                    background-color: #f1faff;
                }
            }
        }
    }
    .product {
        height: calc(100vh - 100px);
        width: 100%;
        margin: 20px;
        overflow-y: auto;
        .product_Card {
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 20px;
            gap: 20px;
            margin: 30px;
        }
        .no-product {
            display: flex;
            font-weight: 600;
            justify-content: center;
        }
    }
}

h1 {
    margin: 10px;
    font-size: 40px;
    color: rgb(1, 1, 59);
}

ul li {
    font-size: 20px;
}
