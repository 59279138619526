

  .progression-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 9999;

    .logo {
      width: 10%;
    }

    .clock-img {
      width: 30px;
      z-index: 9999;
    }
  }

  .progression {
    height: 10px;
    width: 100%;
    background-color: #D9D9D9;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 9999;

    .current-progression {
      height: 10px;
      background-color: #ff8e33;
      border-radius: 5px;
      z-index: 9999;
    }

  }
