.input-label-wrapper {
    margin-bottom: 20px;
    height: 40px;
    .input-label {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;

        label {
            width: 50%;
            text-align: start;
            margin: 0 10px;
            font-size: 14px;
            font-weight: 500;
            font-family: "Roboto";
        }
        .input-wrap {
            border: 1px solid #cfcccc;
            border-radius: 3px;
            input {
                border: none;
                outline: none;
                width: 100%;
                height: 32px;
                padding: 0 10px;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }
            input::placeholder {
                font-size: 16px;
                font-weight: 400;
            }
            input:disabled {
                pointer-events: auto !important;
                cursor: not-allowed !important;
                background-color: #8d8d8d63;
            }
        }
    }

    .error-text-wrap {
        width: 100%;
        margin: 0;
        padding: 3px;
        font-size: 11px;
        text-align: right;
        color: rgb(253, 10, 10);
    }
}
