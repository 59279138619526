.inventoryPage {

    .delete-Popup {
        .MuiDialogTitle-root {
            display: flex;
            justify-content: center;
            color: #ff0000;

            .MuiSvgIcon-root {
                font-size: 40px;
            }
        }
    }
}

.inventory-table {
    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: rgb(235 235 235);
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #9e9e9eb0;
    }

    .btn-wrapper {
        text-align: end;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 20px;
        flex-direction: column;
        cursor: pointer;
        .top-btn{
            display: flex;
            gap: 20px;
        .filter-icon{
            background-color: #ff8e33;
            cursor: pointer;
            box-shadow: 0px 4px 4px rgb(0 0 0 / 30%);
            border-radius: 5px;
            color: #FFF;
            padding: 0 5px;
        }

        .inventory-columns-tag {
            margin: 0;
        }

        .MuiAutocomplete-root {
            margin: 20px 0;
        }

        .MuiOutlinedInput-root {
            padding-right: 0 !important;
        }

        .MuiAutocomplete-tag {
            max-width: 50px;
        }
    }
    }

    .table-wrapper {
        margin: 30px;

        .header-wrapper {
            display: flex;
            margin: 20px 0;
            align-items: center;
            justify-content: space-between;

            .left-content {
                .bulk-upload {
                    .bulk-action-text {
                        display: inline-flex;
                        margin: 0;
                        padding: 12px;
                        color: #090a0c;
                        font-size: 14px;
                        font-weight: bold;
                        font-family: "Lato";
                    }

                    .custom-file-upload {
                        border: 1px solid #ccc;
                        display: inline-flex;
                        border-radius: 5px;
                        padding: 0px 12px;
                        cursor: pointer;
                        background: #ff8e33;
                        font-weight: 600;
                        color: #ffffff;
                        box-shadow: 0 1px 5px rgb(0 0 0 / 16%);

                        input[type="file"] {
                            display: none;
                        }

                        .upload-icon {
                            display: inline-flex;
                            padding-top: 8px;
                        }

                        .txt {
                            display: inline-flex;
                            padding-bottom: 8px;
                        }
                    }
                }
            }
        }
    }

    .table-container {
        height: calc(100vh - 350px);
        overflow-y: auto;

        .edit-btn {
            border-radius: 50%;
            background-color: #ff8e33;
            height: 35px;
            width: 35px;
            padding: 5px;
            float: right;
            align-items: left;
            justify-content: left;
        }

        .inventory-list-table {
            padding: 25px;
            margin: auto;
            // width: fit-content;
            width: 100%;
            border: 1px solid #f5f5f5;
            background-color: #fff;
            box-shadow: 0 1px 5px rgb(0 0 0 / 16%);

            thead {
                position: sticky;
                top: 0;
                background: #fff;
            }

            .action-btn-wrap {
                display: grid;
                grid-template-columns: 50% 50%;
                column-gap: 10px;

                button {
                    max-width: 100px;
                    margin: 0 10px;
                }
            }

            th,
            td {
                border-right: none;
                border-left: none;
            }

            td {
                padding: 20px;
                color: #636363;
                font-size: 13px;
                font-weight: 400;
                z-index: 1;
                text-align: center;
            }

            th {
                padding: 20px 10px;
                color: #090a0c;
                font-size: 15px;
                font-weight: 600;
                top: 0;
                z-index: 1;
                text-align: center;
                font-family: "Roboto";
                border: 0;
                background-color: transparent;

                span {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    justify-content: center;
                }
            }

            td {
                .btn-action {
                    margin: 0 !important;
                    height: 30px;
                    text-transform: uppercase;
                }
            }

            .profile {
                align-items: center;
                padding: 0.325rem 0.5rem;
                font-size: 0.85rem;
                font-weight: 600;
                line-height: 1;
                text-align: center;
                white-space: nowrap;
                border-radius: 0.325rem;
                text-transform: capitalize;
            }

            .online {
                color: #50cd89;
                background-color: #e8fff3;
            }

            .shop {
                color: #bb971a;
                background-color: #fff8dd;
            }

            .seller {
                color: #ff0000;
                background-color: #fff5f8;
            }

            .drop_ship {
                color: #009ef7;
                background-color: #f1faff;
            }
        }
    }
}

.column-name {
    display: flex;
    gap: 10px;
}