.bajaj-sku-table {
    padding: 8px 0px;
    width: 100%;




    .bajaj-sku-manaagement-table {
        width: 100%;

        tr {
            width: 100%;

            th {
                padding: 0px 10px;
            }
        }

        .bajaj-sku-manaagement-table-head {
            display: table;
            width: 100%;
            padding: 0px 20px;
            border-bottom: 1pt solid #FB6C00;

            .bajaj-sku-table-header-category {
                width: 10%;
            }

            .bajaj-sku-table-header-sku {
                width: 40%;
            }

            .bajaj-sku-table-header-mop {
                width: 10%;
            }

            .bajaj-sku-table-header-mrp {
                width: 10%;
            }

            .bajaj-sku-table-header-last-update {
                width: 10%;
            }

            .bajaj-sku-table-header-action {
                display: flex;
                width: 100%;
                justify-content: center;

                p {
                    text-align: center;
                }
            }

            .bajaj-sku-table-header {
                display: flex;
                align-items: center;
                height: 24px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
                color: #FB6C00;
            }
        }

        .Sub-inventory-manaagement-table-body {
            height: calc(100vh - 320px);
            overflow: auto;
            display: block;


            .action-btn {
                display: flex;
                justify-content: center;
            }

            tr {
                padding: 0px 20px;
                width: 100%;

                td {
                    padding: 0px 10px;
                }
            }


            .inventory-manaagement-table-body-row {
                display: table;

                .bajaj-sku-table-body-category {
                    width: 10%;
                }

                .bajaj-sku-table-body-sku {
                    width: 40%;
                }

                .bajaj-sku-table-body-mop {
                    width: 10%;
                }

                .bajaj-sku-table-body-mrp {
                    width: 10px;
                }

                .bajaj-sku-table-body-last-update {
                    width: 10%;
                }

                .bajaj-sku-table-body-action {
                    width: 20%;

                    p {
                        text-align: center;
                    }
                }
            }

            .table-data-item {
                display: flex;
                overflow-wrap: anywhere;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                color: #969494;
            }
        }
    }
}