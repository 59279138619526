.tab-container {
    display: flex;
    justify-content: space-around;
    background-color: #f5f5f5;
    margin: auto;
    width: 80%;
    padding: 10px;
    .tabs {
        border-bottom: 3px solid #ff8e33;
        cursor: pointer;
    }
    span {
        font-weight: 600;
    }
}