.addware-popup {
    .MuiDialog-scrollPaper {
        justify-content: flex-end;
        margin-top: 60px;
    }
    .text-header1 {
        align-items: center;
        padding: 0 10px;
        justify-content: left;
    }

    .MuiDialog-paperWidthSm {
        max-width: 100%;
    }

    .MuiDialog-paperScrollPaper {
        display: block;
        max-height: 100%;
        height: 100vh;
        margin: 0;
        width: 40vw;
    }
    .MuiBackdrop-root {
        background: transparent;
    }
}

.AddressEdit-form {
    padding: 20px;
    .text-label-content {
        border: 1px solid #b8b8b8;
    }
    .pincode-wrapper {
        width: 40%;
    }
    .city-state-wrapper {
        display: flex;
        align-items: center;
        .bussiness-address-content {
            color: #f16800;
            font-size: 14px;
            font-weight: bold;
        }
        .input-with-dropdown-down {
            line-height: 1.7;
            width: 60%;
            padding: 15px;

            .input-field-box {
                padding: 6px;
                border-radius: 4px;
                border: 1px solid #8d8d8da8;
                font-family: "Lato";
                width: 100%;
                height: 36px;
            }
            .txt-label {
                font-size: 14px;
                font-family: "Lato";
                color: #818181;
                width: 50%;
                text-align: left;
            }
        }
    }
    .error-text {
        text-align: left;
    }
    .address-form-btn-wrapper {
        bottom: 10%;
        left: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .btn-action {
            background: #f25822;
            font-family: "Lato";
            font-size: 17px;
            outline: transparent;
            border: none;
            height: 48px;
            padding: 0px 15px;
            color: #fff;
            border-radius: 3px;
            cursor: pointer;
            font-weight: 600;
            width: 160px;
            box-shadow: 0px 3px 6px #00000052;
            &:hover {
                background: #fff2ed;
                color: #f25822;
                font-size: 15px;
                font-weight: 600;
            }
        }
        .btn-outline-wrap {
            width: 100px;
        }
        .btn-color-wrap {
            width: 100px;
        }
        .outlined {
            background: #fff;
            font-family: "Lato";
            outline: transparent;
            border: 0;
            height: 30px;
            padding: 0px 15px;
            color: #090a0c;
            font-size: 18px;
            border-radius: 3px;
            cursor: pointer;
            font-weight: 500;
        }
    }
}
