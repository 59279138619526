.filter-head{
    font-size: 1rem;
    display: grid;
    grid-template-columns: 2% 12% 12% 12% 12% 12% 12% 17% 1.1%;
    padding: 0px;
    align-items: center;
    justify-content: space-around;
    
    .filter-item{
      padding: 10px 5px;
      .btn-action{
        margin: 10px 10px;
      }
    }
    .filter-icon{
      color: #ff632b;
    }
    .btn-container{
        display: flex;
    }
  }
  .empty-data{
      text-align: center;
  }
  .sort-icon{
    font-size: 1rem;
    padding: 0px 5px;
    cursor: pointer;
  }
  // .inventory-manaagement-table{
  //   height: calc(100vh - 300px);
  //       overflow-y: auto;
  // }