.inventory-add-form {
    .Overlay {
        background-color: rgba(0, 0, 0, 0.55);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: fixed;
        display: none;
        z-index: 5;
    }

    .Overlay.Show {
        display: block;
    }

    .Modal {
        bottom: 0;
        background-color: #fff;
        box-shadow: 0 0 4px 0px rgb(0 0 0 / 15%);
        padding: 0 12px 12px;
        z-index: 10;
        height: 100%;
    }

    .Modal.Show {
        height: 100vh;
        right: 0;
        overflow-y: auto;
        overflow-x: hidden;

        .handle-close-ic {
            display: flex;
            margin: 20px;
            justify-content: space-between;

            .close-ic {
                cursor: pointer;
            }

            .history-ic {
                cursor: pointer;
                width: 25px;
                filter: invert(63%) sepia(71%) saturate(969%) hue-rotate(333deg) brightness(98%) contrast(106%);
            }
        }

        .title-content {
            text-align: center;
            color: #ff8e33;
            font-weight: 600;
            font-family: "Roboto";
            font-size: 26px;
            border-bottom: 1px solid rgba(255, 142, 51, 0.5);
        }

        .inventory-form {
            margin: 20px;

            padding: 10px 0;

            .form-top-content {
                display: flex;

                .left-content {
                    width: 50%;
                    padding: 0 20px;
                }

                .right-content {
                    width: 50%;
                    padding: 0 20px;
                }
            }

            .channelErr {
                color: #ff0000;
                text-align: center;
                padding: 10px;
            }

            .channels-table {
                padding: 25px;
                width: 100%;
                border: 1px solid #f5f5f5;
                background-color: #fff;
                box-shadow: 0 1px 5px rgb(0 0 0 / 16%);

                .action-btn-wrap {
                    .btn-color-wrap {
                        width: 100px;
                        margin: 0 10px;
                    }
                }

                th,
                td {
                    border-right: none;
                    border-left: none;
                }

                td {
                    padding: 20px;
                    color: #636363;
                    font-size: 13px;
                    font-weight: 400;
                    z-index: 1;
                    text-align: center;
                }

                th {
                    padding: 20px 10px;
                    color: #090a0c;
                    font-size: 15px;
                    font-weight: 600;
                    top: 0;
                    z-index: 1;
                    text-align: center;
                    font-family: "Roboto";
                    border: 0;
                    background-color: transparent;
                }

                th:first-child,
                th:nth-child(2) {
                    width: auto !important;
                }

                td {
                    .btn-action {
                        margin: 0 !important;
                        height: 30px;
                        text-transform: uppercase;
                    }
                }

                .add-channels-text-wrap {
                    font-family: "Roboto";
                    font-weight: 500;
                    color: #ff8e33;
                    cursor: pointer;
                }

                .txt-field-wrap {
                    font-size: 10px;
                }

                .channel-dropdown {
                    height: 32px;
                    border: 1px solid #cfcccc;
                    border-radius: 3px;
                    padding: 10px;
                    width: 100%;
                    max-width: 175px;

                }

                .autoComplete-dropdown {
                    margin-bottom: 20px;
                }

                .MuiAutocomplete-root {
                    .MuiChip-label {
                        font-size: 11px;
                    }
                }

                .channel-Stock {
                    .input-label {
                        display: unset;
                    }
                }
            }

            .form-btn-wrap {
                display: flex;
                justify-content: center;
                margin: 30px;

                .btn-color-wrap {
                    width: 70px;
                    margin: 0 30px;
                }

                .btn-outline-wrap {
                    width: 70px;
                    margin: 0 30px;
                }
            }

            .checkbox-wrapper {
                display: grid;
                grid-template-columns: 50% 50%;
                align-items: center;
                // margin-bottom: 4rem;
                height: 40px;

                .checkbox-label {
                    text-align: start;
                    margin: 0 10px;
                    font-size: 14px;
                    font-weight: 500;
                    font-family: "Roboto";
                }

                .checkbox {
                    display: grid;
                    grid-template-columns: 50% 50%;
                    column-gap: 10px;
                }
            }
        }
    }
}

.action-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-start;
}

.error-text-wrap {
    width: 100%;
    margin: 0;
    padding: 3px;
    font-size: 11px;
    text-align: center;
    color: rgb(253, 10, 10);
}

.bottom_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.autocomplete-list {
    .MuiAutocomplete-root {
        height: 32px !important;
        border-radius: 3px;
        border: 1px solid #cfcccc;
    }

    .MuiFilledInput-root {
        height: 32px;
        padding-top: 0px !important;
        background-color: unset !important;
    }

    .MuiFilledInput-root:hover {
        background-color: unset !important;
    }

    .MuiFilledInput-root:after {
        border-bottom: unset !important;
    }

    .MuiFilledInput-root::before {
        border-bottom: unset !important;
    }
}

.stock-container {
    margin-bottom: 40px;

    .input-label-wrapper {
        margin-bottom: unset !important;
    }

    .MuiButtonBase-root {
        padding: unset;
    }

    .icon {
        cursor: pointer;
        float: right;
    }
}