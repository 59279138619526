.location-inventory-page {
    ::-webkit-scrollbar {
        display: none;
    }
    .bulk-upload{
        display: flex;
        margin-top: -40px;
        margin-right: 20px;
        justify-content: end;
    .bulk-action-text {
        display: inline-flex;
        margin: 0;
        padding: 12px;
        color: #090a0c;
        font-size: 14px;
        font-weight: bold;
        font-family: "Lato";
        
    }
    .custom-file-upload {
        border: 1px solid #ccc;
        display: inline-flex;
        border-radius: 5px;
        padding: 6px 12px;
        cursor: pointer;
        background: #ff8e33;
        font-weight: 600;
        color: #ffffff;
        box-shadow: 0 1px 5px rgb(0 0 0 / 16%);
        input[type="file"] {
            display: none;
        }
        .upload-icon{
            display: inline-flex;
            padding-top: 8px;
        }
        .txt{
            display: inline-flex;
            padding-bottom: 8px;

        }
    }
}
    .bread-crumb-layout {
        .MuiTypography-root .MuiBreadcrumbs-root .mt-2 .MuiTypography-body1 .MuiTypography-colorTextSecondary {
            margin: 10px 30px;
            a {
                text-decoration: none;
            }
        }
        .MuiBreadcrumbs-ol {
            margin: 10px 30px;
        }
        .bread-crumb-navlink {
            padding: 0 15px;
            height: 30px;
            box-shadow: 0px 1px 5px #00000029;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            border-radius: 5px;
            font-weight: 800;
            border: 1px solid #b8b8c8b5;
        }
    }
    .profile-detils-wrapper {
        display: flex;
    }

    .profile-details-layout {
        display: flex;
        padding: 20px;
        height: calc(100vh - 100px);
        overflow: auto;
        ::-webkit-scrollbar {
            display: none;
        }
        .pd-sidemenu {
            width: 250px;
            box-shadow: 0px 5px 5px #00000029;
            border-radius: 10px;
            height: fit-content;
            padding-bottom: 10px;
            background-color: #fff;
            .layout-Header {
                height: 35px;
                margin: 0;
                box-shadow: 0px 0.5px 1px #00000029;
                background: #0000000d;
                display: flex;
                padding: 8px;
                justify-content: space-around;
                align-items: center;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;

                p {
                    font-weight: 600;
                    color: #090a0c;
                }
            }
            .layout-card-content {
                padding-bottom: 25px;
                background: #ffff;
                .select-content {
                    display: flex;
                    text-decoration: none;
                    align-items: center;
                    justify-content: left;
                    // padding: 0 10px;
                    height: 36px;
                    &.active {
                        .select-indicator {
                            width: 10px;
                            height: 10px;
                            border: 1px solid #090a0c;
                            border-radius: 50px;
                            margin: 10px;
                            background: #fb6c00;
                        }
                        p {
                            color: #090a0c;
                            font-weight: 600;
                        }
                    }
                    .select-indicator {
                        width: 10px;
                        height: 10px;
                        border: 1px solid #bebebe;
                        border-radius: 50px;
                        margin: 10px;
                    }
                    p {
                        color: #b8b8b8;
                    }
                }
            }
        }
        .pd-container {
            background: #ffffff;
            width: 100%;
        }
        .prodout-details-content {
            display: flex;
        }
    }
}

.Addlocations-page {
    .location-layout-wrapper {
        padding: 0px 30px 30px 30px;
        background: #fbfbfb;
        height: calc(100vh - 100px);
        display: grid;
        grid-template-columns: 33.33% 33.33% 33.33%;
        column-gap: 14px;
        overflow: auto;
        .location-core-details {
            width: 100%;
            .legal-details-content {
                background: #ffff;
                box-shadow: 0px 1px 5px #00000029;
                padding-bottom: 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 0 8px 20px;
                .registed-address-content {
                    padding: 5px 15px;
                    line-height: 1.3;
                    ::-webkit-scrollbar {
                        width: 0px;
                    }
                    .address-field {
                        border: 1px solid #e8e9ec;
                        height: 120px;
                        position: relative;
                        margin-top: 5px;
                        border-radius: 5px;
                        padding: 10px;
                        overflow: scroll;
                        min-height: 150px;
                       
                        p {
                            margin: 0;
                            color: #b8b8b8;
                            font-size: 14px;
                            padding-right: 40px;
                        }
                        .verified-icon {
                            position: absolute;
                            color: #b8b8b8;
                            width: 15px;
                            right: 20px;
                            top: 14px;
                            cursor: pointer;
                        }
                    }
                    label {
                        font-size: 14px;
                        color: #818181;
                    }
                }
                .input-with-dropdown-down {
                    line-height: 2;

                    padding: 15px;
                    .txt-label {
                        font-size: 14px;
                        font-family: "Lato";
                        color: #898989;
                        text-align: left;
                    }
                    .input-and-error {
                        width: 100%;
                    }
                    .input-field-box {
                        padding: 6px;
                        border-radius: 2px;
                        font-family: "Lato";
                        width: 100%;
                        height: 36px;
                        border: 1px solid #e8e9ec;
                    }
                    .input-field-box-error {
                        padding: 6px;
                        border-radius: 2px;
                        border: 1px solid #e8e9ec;

                        font-family: "Lato";
                        width: 90%;
                        height: 25px;
                    }
                }
                .working-days-content {
                    padding: 5px 15px;
                    line-height: 2;
                    .text-label-content {
                        right: 10px;
                        position: absolute;
                        &:focus {
                            background-color: #fff7f0;
                            border: 1px solid #ffddbc;
                            outline: none;
                            .hint-text {
                                display: none;
                            }
                        }
                    }
                    .input-with-verified-icon {
                        position: relative;
                        display: flex;
                        padding: 5px;
                        border-radius: 2px;
                        border: 1px solid #e8e9ec;
                        font-family: "Lato";
                        width: 100%;
                        height: 66px;
                        border-radius: 4px;
                        box-sizing: border-box;
                        height: auto;
                        min-height: 53px;
                        .days-wrapper {
                            display: grid;
                            grid-template-columns: 33.33% 33.33% 33.33% ;
                            column-gap: 5px;
                            align-items: center;
                            font-size: 14px;
                            color: #818181;
                            p {
                                margin: 5px;
                            }
                        }
                    }
                    .verified-icon {
                        color: #b8b8b8;
                        right: 0.65vw;
                        position: absolute;
                        width: 14px;
                        top: 12px;
                        cursor: pointer;
                    }
                }
            }
        }
        .warehouse-contacts-wrapper {
           width: 100%;
            .legal-details-content {
                background: #ffff;
                box-shadow: 0px 1px 5px #00000029;
                padding-bottom: 20px;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                padding: 0 8px 20px;
                margin-bottom: 25px;
                .contacts-label {
                    color: #100f0f;
                    font-size: 14px;
                    margin: 0;
                    padding-top: 10px;
                    font-weight: 500;
                }
            }
        }
        .addwarehouse-submit-btn {
            text-align: center;
            margin: 20px 0;
            .btn-color-wrap{
                width: 150px;
            }
        }
        .error-text {
            text-align: left;
        }
    }
}