.login-page {
    background-color: #ffddbc;
    height: 100vh;
    .content-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        .left-content {
            height: 40%;
            width: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #fff;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            img {
                width: 230px;
            }
        }
        .right-content {
            width: 40%;
            height: auto;
            border-radius: 10px;
            box-shadow: 0px 1px 5px #00000029;
            background-color: #fff;
            text-align: center;
        }
    }
}
