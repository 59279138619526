.close-icon{
    position: absolute;
    cursor: pointer;
    right: 10px;
    top: 10px;
}

.dialog-title {
    background-color: aliceblue;
    color: #ff8e33;
}

.cont {
    display: flex;
    //background-color: rgba(0, 0, 0, 0.5);
    flex-direction: column;
    align-items: center;

    .history-head-bajaj-sku {
        width: 40%;
    }

    .history-head-supplier-id {
        width: 15%;
    }

    .history-head-stock {
        width: 8%;
    }

    .history-head-status {
        width: 10%;
    }

    .history-head-mrp {
        width: 10%;
    }

    .history-head-updated-on {
        width: 30%;
    }

    .history-body-bajaj-sku {
        width: 40%;
        text-align: center;
        overflow-wrap: anywhere;
    }

    .history-body-supplier-id {
        width: 15%;
        text-align: center;
    }

    .history-body-stock {
        width: 8%;
        text-align: center;
    }

    .history-body-status {
        width: 10%;
        text-align: center;
    }

    .history-body-mrp {
        width: 10%;
        text-align: center;
    }

    .history-body-updated-on {
        width: 30%;
        text-align: center;
    }


    p {
        overflow-wrap: anywhere;
        text-align: center;
    }
}