.workingDays-form {
    padding: 20px;
    .working-days-text {
        color: #090a0c;
        font-weight: 800;
    }
    .checkbox-layout {
        margin: 30px 0 0;
    }
    .checkbox-wrapper {
        display: flex;
        align-items: center;
        p {
            padding: 0;
            margin: 0;
        }
    }
    .round {
        position: relative;
        display: flex;
        align-items: center;
        margin: 10px;
        justify-content: space-between;
        margin-bottom: 20px;
        .text-danger {
            color: red;
            font-size: 10px;
        }
        .days-content-wrapper {
            margin: 0;
            width: 100px;
        }
        .checkbox-label {
            background-color: #fff;
            border: 1px solid #090a0c;
            border-radius: 50%;
            cursor: pointer;
            height: 10px;
            left: 0;
            top: 0;
            width: 10px;
            margin: 10px;
        }
    }
    .round input[type="checkbox"] {
        visibility: hidden;
    }
    .round input[type="checkbox"]:checked+label {
        background-color: #fb6c00;
        border-color: #090a0c;
    }
    .round input[type="checkbox"]:checked+label:after {
        opacity: 1;
    }
    .error-txt {
        color: #ff0000;
        font-size: 13px;
        align-items: center;
        display: flex;
        padding: 10px 0px;
    }
    .working-hours-wrapper {
        display: flex;
        justify-content: space-around;
        width: 75%;
    }
    .days-hours-height {
        height: 50px;
    }
    .workingdays-form-btn-wrapper {
        bottom: 10%;
        left: 0;
        right: 0;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .btn-outline-wrap {
            width: 100px;
        }
        .btn-color-wrap {
            width: 100px;
        }
        .btn-action {
            background: #f25822;
            font-family: "Lato";
            font-size: 17px;
            outline: transparent;
            border: none;
            height: 48px;
            padding: 0px 15px;
            color: #fff;
            border-radius: 3px;
            cursor: pointer;
            font-weight: 600;
            width: 160px;
            box-shadow: 0px 3px 6px #00000052;
            &:hover {
                background: #fff2ed;
                color: #f25822;
                font-size: 15px;
                font-weight: 600;
            }
        }
        .outlined {
            background: #fff;
            font-family: "Lato";
            outline: transparent;
            border: 0;
            height: 30px;
            padding: 0px 15px;
            color: #090a0c;
            font-size: 18px;
            border-radius: 3px;
            cursor: pointer;
            font-weight: 500;
        }
    }
}